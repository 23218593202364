a {
  opacity: 1 !important;
}

html {
  background-color: #faebd7;
}

a:hover {
  color: orange;
}

div::-webkit-scrollbar {
  display: none;
}

h1 {
  font-weight: 100 !important;
}

h1,
h2,
h3,
h4,
h5 {
  letter-spacing: -1px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div {
  font-family: 'Nexa';
}

p,
h1 {
  margin-bottom: 0
}

h2 {
  font-size: 18px;
  font-family: 'Nexa';
}

p,
small,
span {
  font-size: 13px;
}

h6 {
  font-size: 13px;
}

.badge {
  border: 2px solid black;
  cursor: pointer;
  font-family: 'Nexa';
}

.badge:hover {
  border: 2px solid white
}

.border-right {
  border-right: 1px solid white;
}

.cursor {
  cursor: pointer
}

.fixed-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fw-medium,
.fw-bold {
  font-family: 'Nexa';
  font-weight: 600 !important;
}

.list-group-item {
  background-color: #0a0a0a !important;
  border: none;
  color: white;
  cursor: pointer;
  min-width: 150px;
}

.hover-no-opacity:hover {
  opacity: 1 !important;
}

.shimmer {
  animation: shimmer 5.5s infinite;
  background-repeat: no-repeat;
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 60%) right/700% 100%;
}


@keyframes shimmer {
  100% {
    -webkit-mask-position: left
  }
}

.profile-photo {
  border-radius: 10px;
  position: relative;
  width: 200px;
  z-index: 2;
}


.profile-photo-orange {
  border: 1px solid #08CAF0;
  border-radius: 10px;
  height: 200px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 200px;
  z-index: 0;
}

.animated-border-bottom {
  animation: gradient 30s ease infinite;
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #007AFF 20%, green 40%, #23d5ab 45%, rgba(255, 166, 0, 0.825) 61%, #007AFF 77%, #C850C0 100%);
  background-size: 300% 200%;
  height: 1px;
}

.profile-wrap:hover .profile-photo-orange {
  animation: bubbleBack .4s ease-in;
  animation-fill-mode: forwards;
}

.profile-wrap .profile-photo-orange {
  animation: bubble .4s ease-in;
  animation-fill-mode: forwards;
}

.bg-dark {
  background-color:
    #0a0c0c !important;
}

.rolo {
  background-color: #0a0a0a;
  color: #e6e6e6;
  font-family: 'Dosis', sans-serif;
}

.rolo-border {
  border-bottom: 1px solid gray;
}

.rolo-header-logo {
  font-size: 1.2rem;
  font-weight: 400;
}

.rolo-header-navbar {
  cursor: pointer;
}

.rolo-header-navbar>p {
  padding-left: 4px !important;
  padding-left: 4px !important;
}

.rolo-header-logo:hover {
  cursor: pointer;
  text-decoration: underline;
}

.rolo-header-navbar p:hover {
  font-weight: 700 !important;
  padding: unset !important;
  text-decoration: underline;
}

.underline {
  text-decoration: underline;
}

.centered {
  width: 500px;
  margin-top: 11vh;
}

.centered-wide {
  max-width: 800px;
  margin-top: 11vh;
}


.vh-100 {
  height: calc(100vh - 140px) !important;
}

.vh-100-actual {
  height: 100vh;
}

.delay-animation-none {
  animation: fadeIn 1.21s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.delay-animation-short {
  animation: fadeIn 1.11s;
  opacity: 0;
  animation-delay: 0.25s;
  animation-fill-mode: forwards;
}

.whiteOnHover:hover {
  color: white;
  opacity: 1 !important;
  cursor: pointer;
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 100%;
  }

  50% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 0% 100%;
  }
}

@keyframes bubble {
  from {
    left: 3px;
    top: 3px
  }

  to {
    left: 12px;
    top: 12px
  }
}

@keyframes bubbleBack {
  from {
    left: 12px;
    top: 12px
  }

  to {
    left: 3px;
    top: 3px
  }
}

@keyframes slide {
  0% {
    margin-left: 0px;
  }

  100% {
    margin-left: -100%;
  }
}

.curve {
  position: absolute;
  height: 200px;
  width: 100%;
  bottom: 0;
}

.curve::before {
  content: '';
  position: absolute;
  display: block;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  background-color: #faebd7;
  transform: translate(85%, 60%);
}

.curve::after {
  content: '';
  position: absolute;
  display: block;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  background-color:
    #0a0c0c;
  transform: translate(-4%, 40%);
}

section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 250px;
  padding: 100px 20vw
}

.main-content {
  min-height: 45vh;
}






.greenLady {
  position: absolute;
  height: 110px;
  width: 100%;
  bottom: 30px;
}

.greenLady::before {
  content: '';
  position: absolute;
  display: block;
  border-radius: 14% 65%;
  width: 55%;
  height: 100%;
  background-color: green;
  transform: translate(137%, 32%);
  z-index: 3;
}

.greenLady::after {
  content: '';
  position: absolute;
  display: block;
  border-radius: 87% 46%;
  width: 80%;
  height: 190%;
  background-color: black;
  transform: translate(-4%, 1%);
}

.jerection {
  background-color: green;
  border-radius: 7px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 110px;
  width: 220px;
}

.black-bottom-right {
  background-color: black;
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%
}

.black-bottom-left {
  background-color: black;
  bottom: 0;
  height: 50%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.fw-light {
  font-family: 'NexaLight';
}

.white-bottom {
  background-color: #faebd7;
  height: 110px;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.white-left {
  width: 30px;
  position: absolute;
  height: 150%;
  background-color: #faebd7;
  z-index: 11;
  left: -30px
}

.white-right {
  height: 110%;
  width: 100px;
  background-color: #faebd7;
  z-index: 10;
  position: absolute;
}

.green-lady-face {
  background-color: #F18783;
  border-radius: 50px;
  height: 69px;
  left: 86px;
  position: absolute;
  top: 8px;
  width: 58px;
  z-index: 12;
}

.left-eye {
  background-color: white;
  border-radius: 88% 88%;
  border: 1px solid black;
  position: absolute;
  left: 14px;
  height: 22px;
  width: 15px;
  top: 16px;
}

.right-eye {
  border: 1px solid black;
  position: absolute;
  left: 35px;
  background-color: white;
  border-radius: 88% 88%;
  height: 22px;
  width: 15px;
  top: 16px;
}

.retina {
  height: 97%;
  width: 87%;
  right: 1%;
  position: absolute;
  background-color: black;
  border-radius: 88%;
}





.orangeLady {
  position: absolute;
  height: 110px;
  width: 100%;
  bottom: 30px;
}

.orangeLady::after {
  content: '';
  position: absolute;
  display: block;
  border-radius: 81% 36%;
  width: 63%;
  height: 80%;
  background-color: black;
  transform: translate(-2%, 45%);
}

.orangejerection {
  background-color: #EB6405;
  border-radius: 7px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 110px;
  width: 95px;
}

.orange1 {
  background-color: #EB6405;
  border-radius: 70% 20%;
  content: '';
  display: block;
  height: 60%;
  position: absolute;
  transform: translate(62%, 92%);
  width: 60%;
  z-index: 9;
}

.orangepin {
  background-color: #EB6405;
  bottom: 43%;
  content: '';
  display: block;
  position: absolute;
  width: 13%;
  height: 10%;
  right: 0;
  z-index: 13;
}

.forearm {
  content: '';
  position: absolute;
  border-radius: 100%;
  display: block;
  width: 21%;
  height: 15%;
  background-color: black;
  z-index: 13;
  bottom: 42%;
  right: 7%;
}

.hand {
  background-color: #F18783;
  border-radius: 30% 30%;
  bottom: 42%;
  content: '';
  display: block;
  height: 9%;
  position: absolute;
  right: 9%;
  width: 15%;
  z-index: 13;
}

.face1 {
  background-color: #F18783;
  border-radius: 50px;
  position: absolute;
  height: 22%;
  left: 33%;
  top: 20%;
  width: 19%;
  z-index: 12;
}

.eye1 {
  border: 1px solid black;
  background-color: white;
  border-radius: 88% 88%;
  position: absolute;
  top: 25%;
  left: 38%;
  height: 7%;
  width: 5%;
  z-index: 13;
}

.righteye1 {
  background-color: white;
  border: 1px solid black;
  border-radius: 88% 88%;
  height: 7%;
  left: 44%;
  position: absolute;
  top: 25%;
  width: 5%;
  z-index: 13;
}

.retina1 {
  height: 95%;
  width: 85%;
  right: -2%;
  top: 1%;
  position: absolute;
  background-color: black;
  border-radius: 88%;
}

.rabbit {
  content: '';
  position: absolute;
  border-radius: 50% 0;
  display: block;
  width: 100%;
  height: 50%;
  background-color: #A79C9B;
  z-index: 8;
  bottom: 0%;
  right: -50%;
}

.f1 {
  bottom: 42%;
  content: '';
  display: block;
  position: absolute;
  width: 1%;
  height: 6%;
  background-color: black;
  z-index: 15;
  right: 12%;
}

.f2 {
  bottom: 42%;
  content: '';
  display: block;
  position: absolute;
  width: 1%;
  height: 6%;
  background-color: black;
  right: 15%;
  z-index: 15;
}

.f3 {
  content: '';
  position: absolute;
  display: block;
  width: 1%;
  height: 6%;
  background-color: black;
  z-index: 15;
  bottom: 42%;
  right: 18%;
}







@media screen and (max-height: 1038px) {
  .fuckesusu { display: none;}
}

@media screen and (max-width: 992px) {
  .centered {
    text-align: center;
    width: 100%;
  }

  .mobile-padding {
    padding: 0 2rem;
  }

  .profile-photo-orange {
    display: none
  }

  .profile-photo {
    display: none
  }

  .curve {
    height: 119px;
  }
}