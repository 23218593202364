/* #root {
    max-width: 1280px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
  }
   */
  /* Custom CSS Below! Mainly flexbox stuff that mimics Bootstrap's utility classes, as well as margins && padding  */
  
  .align-item-center {
    align-items: center;
  }
  
  .card {
    border: 1px solid #404040;
    border-radius: 5px;
    background-color: black;
    padding: 1em 5px 5px 5px;
  }
  
  .circle-icon svg {
    color: #89CFF0;
  }
  .circle-icon-clicked {
    border: 1px solid #89CFF0 !important;
  }
  
  .d-flex {
    display: flex;
  }
  
  .d-flex-vertical {
    flex-direction: column;
    min-height: 120px;
  }
  
  fieldset { 
    border: none;
    padding: 0 !important;
  }
  
  .gap-1 {
    gap: 3px;
  }
  
  .gap-2 {
    gap: 6px;
  }
  
  .gap-3 {
    gap: 16px;
  }
  
  .justify-content-between {
    justify-content: space-between;
  }
  
  .justify-content-center {
    justify-content: center;
  }
  
  .justify-content-end {
    justify-content: end;
  }
  
  .mb-2 {
    margin-bottom: 6px;
  }
  
  .mb-3 {
    margin-bottom: 16px;
  }
  
  .opacity-75 {
    opacity: .75;
  }
  
  .round-btn {
    background-color: #121212;
    border: 1px solid #404040;
    border-radius: 17px;
    cursor: pointer;
    height: 34px;
    position: relative;
    width: 34px;
  }
  
  .round-btn svg {
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
  }
  
  .square-icon svg {
    color: orange;
  }
  .square-icon-clicked {
    border: 1px solid orange !important;
  }
  
  .submit-btn-disabled {
    background-color: #404040;
    border: 2px solid #404040;
    border-radius: 5px;
    padding: 4px 12px;
  }
  .submit-btn-disabled:focus, .submit-btn-disabled:hover {
    outline: 0.75em solid red;
  }
  
  .submit-btn-enabled {
    background-color: transparent;
    border: 1px solid #32CD32;
    border-radius: 5px;
    color: #32CD32;
    font-weight: 700;
    padding: 4px 12px;
  }
  .submit-btn-enabled:focus, .submit-btn-enabled:hover {
    outline: 0.75em solid #32CD32;
  }
  
  .text-end {
    text-align: right;
  }

  .text-pink {
    color: pink;
  }
  
  .text-xs {
    font-size: 8px;
  }
  
  .triangle-icon svg {
    color: #FF13F0;
  }
  .triangle-icon-clicked {
    border: 1px solid #FF13F0;
  }
  
  .uppercase {
    text-transform: uppercase;
  }
  
  .w-100 {
    width: 100%;
  }
  
  @media screen and (max-width: 350px) {
    .card {
        width: 100%;
        text-align: center;
    }
    .xs-breakpoint-vertical {
      flex-direction: column;
    }
  }


  /* :root {
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;
  
    color-scheme: light dark;
    color: rgba(255, 255, 255, 0.87);
    background-color: #242424;
  
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } */
  
  a {
    font-weight: 500;
    color: #646cff;
    text-decoration: inherit;
  }
  a:hover {
    color: #535bf2;
  }
  
  .body {
    margin: 0;
    display: flex;
    place-items: center;
    justify-content: center;
    background-color: #121212;
    min-height: 100vh;
  }
  p, small, label { color: lightgray; }
  h1 {
    font-size: 3.2em;
    line-height: 1.1;
  }
  
  textarea{ background-color: black; color: white ;}
  textarea:focus {
    outline-color: gray !important;
  }
  button {background-color: black;}